.add-product{
    .image-placeholder{
        .avatar-preview{
            height: 14rem !important;
        }
        .delete-img{
            position: absolute;
            top: 16px;
            right: 16px;
            padding: 2px 6px;
            z-index: 1;
            background: #fff;
            border: 0;
            border-radius: 50%;
            .la{
                color: var(--primary);
                font-size: 18px;
            }
        }
        img{
            border-radius: 30px;
        }
    }
}
.css-1nmdiq5-menu{
    z-index: 999999!important;
}

.color{
    font-size: 20px;
    margin-top: 3px;
    color: #fff;
}

.or{
    position: relative;
}
.or::before,
.or::after{
    content: '';
    position: absolute;
    top: 50%;
    width: 5.8rem;
    height: 1px;
    background-color: #dedede;
}
.or::before{
    left: 0;
}
.or::after{
    right: 0;
}
  