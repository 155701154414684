.invoice-header{
    background-color: rgb(222 222 222 / 21%);
    border: 1px solid #c3c1c1;
    border-radius: 12px;
    padding: 13px;
    img{
        width: 5rem;
    }
}
.product-card{
    border: 1px solid #dedede;
    padding: 25px 0;
}